import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useTheme } from '@mui/system'
import { ImageArtDirection } from '@bluheadless/ui/src/particles/art-direction'
import Button from '@bluheadless/ui/src/molecules/button'
import RichContent from '@bluheadless/ui/src/particles/rich-content'
import {
	CloseWrapper,
	CollapseStyled,
	ContentContainer,
	Root,
	TextContainer,
	TextContainer2,
	Title,
	TitleContainer,
	ImageWrapper,
	TitleMob,
} from './banner-multiple-ctas.styled'
import { useMediaQuery } from '@mui/material'
import { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { IMAGE_COMPONENT, IMAGE_BACKGROUND_COMPONENT } from '@bluheadless/ui-logic/src/constants'

const BannerMultipleCtas = ({
	aboveTheFold,
	className,
	content,
	cssProp,
	image: { desktop, mobile },
	title,
	titleTag,
}) => {
	const { siteName } = useConfig()

	const theme = useTheme()
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))
	const textContainerRef = useRef(null)
	const contentRef = useRef(null)
	const [animate, setAnimate] = useState(false)

	return (
		<Root css={cssProp} aboveTheFold={aboveTheFold}>
			<ImageWrapper animate={animate}>
				<ImageArtDirection
					aboveTheFold={aboveTheFold}
					type={aboveTheFold ? IMAGE_BACKGROUND_COMPONENT : IMAGE_COMPONENT}
					alt={title + ' - ' + siteName}
					sources={{
						xs: {
							src: mobile.src,
							originalHeight: mobile.originalHeight,
							originalWidth: mobile.originalWidth,
						},
						md: {
							src: desktop.src,
							originalHeight: desktop.originalHeight,
							originalWidth: desktop.originalWidth,
						},
					}}
				/>
			</ImageWrapper>

			<TextContainer
				hasOpacity={className?.search('opacity') >= 0}
				onMouseEnter={() => {
					if (textContainerRef?.current && !textContainerRef.current.classList.contains('shady')) {
						textContainerRef.current.classList.add('shady')
					}

					if (mdUp && !animate) {
						setAnimate(true)
					}
				}}
				onMouseLeave={() => {
					if (textContainerRef?.current && textContainerRef.current.classList.contains('shady')) {
						textContainerRef.current.classList.remove('shady')
					}

					if (mdUp) {
						setAnimate(false)
					}
				}}
				ref={textContainerRef}
			>
				<TextContainer2>
					<TitleContainer
						onClick={() => {
							if (contentRef?.current) {
								if (contentRef.current.classList.contains('animated')) {
									contentRef.current.classList.remove('animated')
								} else {
									contentRef.current.classList.add('animated')
								}
							}
						}}
					>
						<Title color="common.white" component={titleTag} variant="headlineBiggest" className={className}>
							{title}
						</Title>
					</TitleContainer>

					{content && (
						<ContentContainer animate={className?.search('animate-left') >= 0 ? 'left' : 'right'} ref={contentRef}>
							{!mdUp && (
								<TitleMob variant="headlineBiggest" className={className}>
									{title}
								</TitleMob>
							)}
							<CollapseStyled in={!mdUp || (mdUp && animate)}>
								<RichContent content={content} />

								{!mdUp && (
									<CloseWrapper>
										<Button
											onClick={() => {
												if (contentRef?.current && contentRef.current.classList.contains('animated')) {
													contentRef.current.classList.remove('animated')
												}
											}}
											size="small"
											variant="text"
										>
											<FormattedMessage id="close" />
										</Button>
									</CloseWrapper>
								)}
							</CollapseStyled>
						</ContentContainer>
					)}
				</TextContainer2>
			</TextContainer>
		</Root>
	)
}

export default BannerMultipleCtas
